import {
  WaypointLabelRender,
  WaypointPosition,
} from "@/components/r3f/renderers/waypoint-label-render";
import { useWalkPlaceholderPositions } from "@/hooks/use-walk-placeholder-positions";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import {
  selectObjectVisibility,
  selectShouldShowWaypointsOnFloors,
  selectVisibilityDistance,
} from "@/store/view-options/view-options-selectors";
import { ViewObjectTypes } from "@/store/view-options/view-options-slice";
import { offsetPlaceholders } from "@/utils/offset-placeholders";
import {
  LocationPlaceholderDefault,
  LocationPlaceholderHover,
  PanoramaPlaceholder,
  parseVector3,
  useOverrideCursor,
  useSvg,
} from "@faro-lotv/app-component-toolbox";
import {
  IElementGenericImgSheet,
  IElementImg360,
} from "@faro-lotv/ielement-types";
import { ThreeEvent } from "@react-three/fiber";
import { DomEvent } from "@react-three/fiber/dist/declarations/src/core/events";
import { useCallback, useMemo, useState } from "react";
import { Frustum, Plane, Texture, Vector3 } from "three";
import { selectBestModelCameraFor360 } from "./animations/pano-to-model";

/** Minimum distance to consider a placeholder click */
const MIN_PLACEHOLDER_CLICK_DISTANCE = 0.1;

export type WalkPlaceholdersProps = {
  /** All the placeholders for this pano */
  placeholders: IElementImg360[];

  /** The current image sheet to use to place the placeholders */
  sheet?: IElementGenericImgSheet;

  /** Optional clipping planes */
  clippingPlanes?: Plane[];

  /** Callback to signal a placeholder have been clicked */
  onPlaceholderClick?(element: IElementImg360, position: Vector3): void;

  /**
   * True to render the placeholders
   *
   * @default true
   */
  visible?: boolean;

  /**
   * True to enable the distance fade off of the placeholders
   *
   * @default false
   */
  shouldFadeOff?: boolean;
};

/**
 * @returns A component to render all the placeholders to navigate in panorama mode
 */
export function WalkPlaceholders({
  placeholders,
  sheet,
  onPlaceholderClick,
  clippingPlanes,
  shouldFadeOff = false,
  visible = true,
}: WalkPlaceholdersProps): JSX.Element | null {
  const [isHovered, setIsHovered] = useState(false);

  const defaultTexture = useSvg(LocationPlaceholderDefault, 512, 512);
  const hoverTexture = useSvg(LocationPlaceholderHover, 512, 512);

  const clipFrustum = useMemo(() => {
    const cp = clippingPlanes;
    return cp?.length === 6
      ? new Frustum(cp[0], cp[1], cp[2], cp[3], cp[4], cp[5])
      : undefined;
  }, [clippingPlanes]);

  // Placeholders will be shown at the scan position and they will face the camera if this option is disabled
  // Otherwise they are placed on the floor, looking up
  const shouldShowWaypointsOnFloor = useAppSelector(
    selectShouldShowWaypointsOnFloors,
  );

  // Use the scan positions if the option to show the waypoints on the floor is disabled
  const positions = useWalkPlaceholderPositions(
    placeholders,
    sheet,
    !shouldShowWaypointsOnFloor,
  );

  const { visiblePlaceholders, visiblePositions } =
    useMemo<VisiblePlaceholders>(() => {
      // If no clipping planes are provided, then all placeholders are visible
      if (!clipFrustum || placeholders.length !== positions.length) {
        return {
          visiblePlaceholders: placeholders,
          visiblePositions: positions,
        };
      }

      // Only placeholders inside the clipping frustum are visible
      const visiblePlaceholders: IElementImg360[] = [];
      const visiblePositions: Vector3[] = [];
      for (let i = 0; i < placeholders.length; i++) {
        const placeholder = placeholders[i];
        if (clipFrustum.containsPoint(positions[i])) {
          visiblePlaceholders.push(placeholder);
          visiblePositions.push(positions[i]);
        }
      }
      return { visiblePlaceholders, visiblePositions };
    }, [clipFrustum, placeholders, positions]);

  const { placeholdersOffset, shiftedPlaceholders } = useMemo(
    () => offsetPlaceholders(visiblePositions),
    [visiblePositions],
  );

  const waypoints = useMemo(() => {
    if (visiblePlaceholders.length !== visiblePositions.length) {
      return new Array<WaypointPosition>();
    }
    return visiblePlaceholders.map((pano, index) => {
      return { pano, renderPosition: visiblePositions[index] };
    });
  }, [visiblePlaceholders, visiblePositions]);

  useOverrideCursor("pointer", isHovered);

  const shouldWayPointsBeVisible = useAppSelector(
    selectObjectVisibility(ViewObjectTypes.waypoints),
  );

  const hasWaypointLabelFeature = useAppSelector(
    selectHasFeature(Features.DisplayWayPointLabel),
  );
  const shouldDisplayWaypointLabels = useAppSelector(
    selectObjectVisibility(ViewObjectTypes.waypointLabels),
  );
  if (!visible || !shouldWayPointsBeVisible) {
    return null;
  }

  return (
    <>
      <group
        position={placeholdersOffset}
        name="placeholders"
        onPointerEnter={() => setIsHovered(true)}
        onPointerLeave={() => setIsHovered(false)}
      >
        {visiblePlaceholders.map((el, index) => (
          <WalkPlaceholder
            key={el.id}
            element={el}
            position={shiftedPlaceholders[index]}
            sheet={sheet}
            onPlaceholderClick={onPlaceholderClick}
            defaultTexture={defaultTexture}
            hoverTexture={hoverTexture}
            shouldFadeOff={shouldFadeOff}
            shouldFaceCamera={!shouldShowWaypointsOnFloor}
          />
        ))}
      </group>
      {
        // Render waypoint labels
        hasWaypointLabelFeature && shouldDisplayWaypointLabels && (
          <WaypointLabelRender waypoints={waypoints} />
        )
      }
    </>
  );
}

type WalkPlaceholderProps = Pick<
  WalkPlaceholdersProps,
  "sheet" | "onPlaceholderClick" | "shouldFadeOff"
> & {
  /** The 360 element whose placeholder we want to render */
  element: IElementImg360;

  /** The floor position for this element */
  position: Vector3;

  /** Texture used for the default state */
  defaultTexture: Texture;

  /** Texture used for the hover state */
  hoverTexture: Texture;

  /** True if the placeholders should face the camera */
  shouldFaceCamera?: boolean;
};

/** @returns A img360 placeholder for walk mode */
function WalkPlaceholder({
  element,
  position,
  sheet,
  shouldFadeOff = false,
  shouldFaceCamera,
  defaultTexture,
  hoverTexture,
  onPlaceholderClick,
}: WalkPlaceholderProps): JSX.Element {
  const store = useAppStore();

  const placeholderClicked = useCallback(
    (ev: ThreeEvent<DomEvent>) => {
      // Prevent the click event if the user clicks a placeholder too close to the camera
      // But don't stop the propagation, so that elements behind the placeholder can still be clicked
      if (ev.distance < MIN_PLACEHOLDER_CLICK_DISTANCE) {
        return;
      }
      ev.stopPropagation();
      if (onPlaceholderClick) {
        const position = selectBestModelCameraFor360(
          element,
          sheet,
        )(store.getState());
        onPlaceholderClick(element, parseVector3(position));
      }
    },
    [element, onPlaceholderClick, sheet, store],
  );

  const visibilityDistance = useAppSelector(selectVisibilityDistance);
  return (
    <PanoramaPlaceholder
      key={element.id}
      shouldFadeOff={shouldFadeOff}
      fadeDistance={visibilityDistance}
      shouldFaceCamera={shouldFaceCamera}
      position={position}
      defaultTexture={defaultTexture}
      hoverTexture={hoverTexture}
      onClicked={placeholderClicked}
    />
  );
}

type VisiblePlaceholders = {
  visiblePlaceholders: IElementImg360[];
  visiblePositions: Vector3[];
};
