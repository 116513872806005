import { useWaypointAltitudeRange } from "@/modes/mode-data-context";
import { useAppSelector } from "@/store/store-hooks";
import { selectShouldColorWaypoints } from "@/store/view-options/view-options-selectors";
import {
  MAX_ALTITUDE_COLOR,
  MIN_ALTITUDE_COLOR,
} from "@/utils/waypoints-color-gradient";
import { BuildingElevationIcon, ColorBar, neutral } from "@faro-lotv/flat-ui";
import { Stack, SxProps } from "@mui/system";

type AppColorBarProps = {
  /** The style applied to the stack containing the color bar */
  sx?: SxProps;
};

/** @returns The vertical color bar used to colorize the waypoints on the sheet */
export function AppColorBar({ sx }: AppColorBarProps): JSX.Element | null {
  const waypointsAltitudeRange = useWaypointAltitudeRange();
  const isColorWaypointOptionEnabled = useAppSelector(
    selectShouldColorWaypoints,
  );

  if (!waypointsAltitudeRange || !isColorWaypointOptionEnabled) return null;

  return (
    <Stack gap={0.5} sx={sx}>
      <BuildingElevationIcon
        sx={{ width: "16px", height: "16px", color: neutral[100] }}
      />
      <ColorBar
        direction="vertical"
        colors={[MIN_ALTITUDE_COLOR, MAX_ALTITUDE_COLOR]}
        sx={{ height: "100%" }}
      />
    </Stack>
  );
}
